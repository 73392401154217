import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
//import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <GatsbyImage
          image={imageNodes[0].node.childImageSharp.gatsbyImageData}
          objectPosition="50% 50%"
          layout="constrained"
          alt=""
        />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        2018年10月30日から東京・有楽町の東京国際フォーラムで開催されたパナソニック株式会社創業100周年記念イベント「クロスバリューイノベーションフォーラム2018」におけるメインステージおよび6つのテーマ展示のステージ映像の制作、全体のアートディレクションを担当。
      </p>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Design', 'Video Production'],
    awards: ['映文連アワード2019 部門優秀賞 コーポレート・コミュニケーション部門'],
    outline: OutlineContent
  };

  /*
  const relatedLinks = [
    {text: "関連コンテンツへのリンク", href: "https://google.co.jp"}
  ];
  */

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
      //relatedLinks={relatedLinks}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 16)} column={1} />
      </WorkDetailImageGallery>
      <WorkDetailCredit>
        <Col lg={6} as="ul" className="mb-0">
          <li>
            <h5>Space Design:</h5>
            古見修一（SD）
          </li>
          <li>
            <h5>Producer:</h5>
            <Link to="/members/serizawa/">芹澤博雅</Link>
          </li>
          <li>
            <h5>Producer:</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Art Direction:</h5>
            <Link to="/members/ochiai-masao/">落合正夫</Link>
          </li>
          <li><h4>「Vision Theater」</h4></li>
          <li>
            <h5>Producer:</h5>
            <Link to="/members/yanagimoto/">柳本歩規</Link>
          </li>
          <li>
            <h5>Producer:</h5>
            <Link to="/members/ota/">大田俊吾</Link>
          </li>
          <li>
            <h5>Production Manager：</h5>
            北村真彩
          </li>
          <li>
            <h5>Director：</h5>
            <Link to="/members/ochiai-masao/">落合正夫</Link>
          </li>
          <li>
            <h5>Compositor：</h5>
            <Link to="/members/tsubota/">坪田篤人</Link>
          </li>
          <li>
            <h5>Compositor：</h5>
            <Link to="/members/ishikawa/">石川陽介</Link>
          </li>
          <li>
            <h5>Colorist：</h5>
            <Link to="/members/kubota/">久保田尚</Link>
          </li>
          <li>
            <h5>VFX_1：</h5>
            DARUMA Inc
          </li>
          <li>
            <h5>VFX_2：</h5>
            Sonic Vision Create Inc.
          </li>
          <li>
            <h5>Modeling：</h5>
            戸高 直（Motion Pixel）
          </li>
          <li>
            <h5>Music：</h5>
            山崎 功（PINTON）
          </li>
          <li><h4>France location</h4></li>
          <li>
            <h5>Coordinate：</h5>
            Sonoko Div Noguchi
          </li>
          <li>
            <h5>Cinematographer：</h5>
            Mari Yamamura
          </li>
          <li>
            <h5>Production：</h5>
            FROGGIE PRODUCTION
          </li>
          <li><h4>Japan location</h4></li>
          <li>
            <h5>Cinematographer：</h5>
            <Link to="/members/ochiai-tomonari/">落合智成</Link>
          </li>
          <li>
            <h5>Gaffer：</h5>
            小峯睦男
          </li>
          <li>
            <h5>DIT：</h5>
            <Link to="/members/kokubo/">小久保尚志</Link>
          </li>
          <li>
            <h5>Key Grip：</h5>
            槙良輔（GRIFFITH）
          </li>
          <li>
            <h5>Stylist：</h5>
            小泉美智子
          </li>
          <li>
            <h5>Hair&amp;Make up：</h5>
            大門友子
          </li>
          <li>
            <h5>Art Design：</h5>
            NOUVELLE VAGUE
          </li>
        </Col>
        <Col lg={6} as="ul" className="mb-0">
          <li><h4>Stage-1「LIFE IMAGINED」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Production Manager：</h5>
            <Link to="/members/hayamizu/">早水祐希</Link>
          </li>
          <li>
            <h5>Director：</h5>
            David 春山祥一
          </li>
          <li>
            <h5>Production：</h5>
            CINQ CO.,LTD
          </li>
          <li><h4>Stage-2「DIVERSE COMMUNITY」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Production Manager：</h5>
            <Link to="/members/hayamizu/">早水祐希</Link>
          </li>
          <li>
            <h5>Director：</h5>
            平方宏明（ハラッパ！）
          </li>
          <li>
            <h5>Production：</h5>
            WEEK’S INC.
          </li>
          <li><h4>Stage-3「MOBILITY ISLAND」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Production Manager：</h5>
            <Link to="/members/hayamizu/">早水祐希</Link>
          </li>
          <li>
            <h5>Director：</h5>
            平方宏明（ハラッパ！）
          </li>
          <li>
            <h5>Production：</h5>
            WEEK’S INC.
          </li>
          <li><h4>Stage-4「CITY ACTIVATION」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Production Manager：</h5>
            <Link to="/members/hayamizu/">早水祐希</Link>
          </li>
          <li>
            <h5>Direction/Production：</h5>
            amana.inc
          </li>
          <li><h4>Stage-5「GEMBA PROCESS INNOVATION」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/yanagimoto/">柳本歩規</Link>
          </li>
          <li>
            <h5>Direction/Production：</h5>
            glow’s
          </li>
          <li>
            <h5>Composer：</h5>
            塚越有人
          </li>
          <li><h4>Stage-6「TECHNOLOGY INNOVATION」</h4></li>
          <li>
            <h5>Producer：</h5>
            <Link to="/members/matsuse/">松瀬友樹</Link>
          </li>
          <li>
            <h5>Direction/Production：</h5>
            slanted IEMOTO INC.
          </li>
        </Col>
      </WorkDetailCredit>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/pana_100th"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/pana_100th.*"}) {
      publicURL
    }
  }
`;
