import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const WorkDetailCredit = ({ children }) => {
  return (
    <Container fluid className="workCredit">
      <Row>
        <Col lg={12}>
          <h4 className="titleLabel">Credit</h4>
          <Row>
            {children}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkDetailCredit;
